.edit-details-container {
  width: 90%;
  max-width: 1024px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.edit-details-container .company-details-form .form-sections {
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}
.edit-details-container .company-details-form .input-label {
  width: 18%;
}
@media (max-width: 896px) {
  .edit-details-container .company-details-form .input-label {
    width: 100%;
  }
}
.edit-details-container .shortField .input-label {
  width: 36%;
}
.edit-details-container .shortField .input-box {
  max-width: 100px;
}
.edit-details-container .form-bottom {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.edit-details-container .form-bottom .save-btn {
  width: 100%;
  margin-top: 15px;
  max-width: 250px;
  text-align: center;
}
@media (max-width: 896px) {
  .edit-details-container .form-bottom .save-btn {
    max-width: unset;
  }
}
.edit-details-container .back-button {
  appearance: none;
  background: transparent;
  border: none;
  display: block;
  position: absolute;
  left: 0px;
  top: 39px;
  font-size: 16px;
  font-weight: normal;
  font-family: 'Roboto-Regular';
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 896px) {
  .edit-details-container .back-button {
    position: absolute;
    top: 0.8%;
    left: 0;
    padding-top: 1.2em;
  }
}
.edit-details-container .back-button img {
  padding-right: 5px;
  width: 6px;
}
@media (max-width: 896px) {
  .edit-details-container .step-count {
    position: absolute;
    top: 0.8%;
    right: 0;
  }
}
.edit-details-container .section-header {
  padding-top: 2.5em;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
@media (max-width: 896px) {
  .edit-details-container .section-header {
    padding-top: 5em;
    padding-bottom: 1em;
  }
}
.edit-details-container .section-header .section-title {
  text-align: center;
  color: #048041;
  text-transform: uppercase;
  font-size: 24px;
  font-family: 'Roboto-Medium';
  font-weight: normal;
  max-width: 600px;
  margin: auto;
}
.edit-details-container .scrollable {
  overflow-y: auto;
  max-height: 300px;
}
.edit-details-container .container-box {
  width: 94%;
  text-align: left;
  box-shadow: 0px 0px 0px #5A71D01B;
  border: 0.5px solid #98989A;
  padding: 1.5em;
  text-align: center;
}
.edit-details-container .container-box .container-box {
  width: auto;
}
.edit-details-container .container-box .agree-to-terms {
  display: flex;
  font-family: 'Roboto-Regular';
  color: #048041;
  margin-top: 1em;
}
.edit-details-container .container-box .agree-to-terms p {
  font-size: 16px;
  padding-left: 1em;
  margin-top: 15px;
}
.edit-details-container .container-box .subtitle {
  color: #048041;
  font-size: 24px;
  text-align: left;
  font-family: 'Roboto-Medium';
}
.edit-details-container .container-box .title {
  color: #DBDBDB;
  font-weight: bold;
  text-align: left;
  font-family: 'Roboto-Bold';
  font-size: 18px;
}
.edit-details-container .container-box p {
  font-size: 18px;
  text-align: left;
  font-family: 'Roboto-Regular';
}
@media (max-width: 896px) {
  .edit-details-container .container-box {
    width: 90%;
    margin: auto;
    text-align: center;
  }
}
.edit-details-container .container-box .vertical-separator-box {
  display: flex;
  opacity: 0.5;
  flex: 8%;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.edit-details-container .container-box .vertical-separator-box .separator {
  background-color: #048041;
  width: 1px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 32%;
  height: 90%;
}
@media (max-width: 896px) {
  .edit-details-container .container-box .vertical-separator-box {
    display: none;
  }
}
.edit-details-container .container-box .form-sections {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
@media (max-width: 896px) {
  .edit-details-container .container-box .form-sections {
    flex-direction: column;
  }
}
.edit-details-container .container-box .form-sections .form-section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 44%;
}
@media (max-width: 896px) {
  .edit-details-container .container-box .form-sections .form-section {
    flex: unset;
  }
}
@media (max-width: 896px) {
  .edit-details-container .container-box .form-sections .form-section .info-icon {
    display: none;
  }
}
@media (min-width: 896px) {
  .edit-details-container .container-box .form-sections .form-section .q-dropdown {
    display: grid;
    grid-template-columns: 90% 10%;
  }
  .edit-details-container .container-box .form-sections .form-section .q-dropdown .input-parent .input-container {
    align-items: center;
  }
  .edit-details-container .container-box .form-sections .form-section .q-dropdown .input-parent .input-container .input-label {
    width: 55%;
  }
  .edit-details-container .container-box .form-sections .form-section .q-dropdown .input-parent .input-container .input-box {
    width: 64.5%;
    height: 40px;
  }
  .edit-details-container .container-box .form-sections .form-section .q-dropdown .info-icon {
    padding-top: 50px;
  }
}
.edit-details-container .container-box .form-sections .form-section .input-container {
  margin-top: 15px;
}
@media (max-width: 896px) {
  .edit-details-container .container-box .form-sections .form-section .input-container {
    margin-top: 5px;
  }
}
.edit-details-container .container-box-legal {
  border: solid 0.5px #535765;
  width: 80vw;
  margin: auto;
  min-height: 30vh;
  padding: 1.5em;
  text-align: justify;
}
.edit-details-container .container-box-legal p {
  font-size: 16px;
  font-family: 'Roboto-Regular';
}
.edit-details-container .container-box-legal p strong {
  font-size: 16px;
  text-transform: uppercase;
  font-family: 'Roboto-Medium';
  color: #048041;
}
.edit-details-container .container-box-legal p a {
  text-decoration: underline;
}
