/*******************************/
/********** Header CSS *********/
/*******************************/
.modal-content .modal-section {
  width: 76%;
}
.modal-content .extra {
  width: 90%;
}
.header {
  position: fixed;
  width: 100%;
  padding: 10px 0 0 0;
  display: flex;
  flex-direction: row;
  box-shadow: 0px 3px 6px #00000029;
  min-height: 80px;
  background-color: #FFFFFF;
  z-index: 5;
}
@media (max-width: 1200px) {
  .header {
    padding: 0;
  }
}
.header .header-inner-container {
  max-width: 1366px;
  margin: 0 auto;
  width: 80vw;
  display: flex;
  flex-direction: row;
  position: relative;
}
@media (max-width: 1200px) {
  .header .header-inner-container {
    width: 100vw;
  }
}
.header .header-inner-container nav {
  width: 100%;
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header .header-inner-container nav .nav-ul li {
  cursor: pointer;
  text-transform: uppercase;
  font-size: 16px;
  color: #98989A;
}
.header .header-inner-container nav .nav-ul li a {
  color: #98989A;
}
.header .header-inner-container nav .nav-ul li:hover {
  opacity: 0.7;
}
.header .header-inner-container nav .menu-expand li {
  font-size: 18px;
  color: #98989A;
  text-align: center;
}
.header .header-inner-container nav .menu-expand li a {
  color: #FFFFFF;
}
@media (max-width: 500px) {
  .header .header-inner-container nav .menu-expand li {
    color: #FFFFFF;
  }
}
.header .header-inner-container nav .logo {
  padding: 15px 0;
  display: flex;
  height: 60px;
  width: 370px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('../../../images/ESB_CESVectorLogoWithSolgan.svg');
}
@media (max-width: 500px) {
  .header .header-inner-container nav .logo {
    width: 270px;
  }
}
.modal-content .programs {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 90px;
}
@media (max-width: 500px) {
  .modal-content .programs {
    flex-direction: column;
  }
}
.modal-content .programs .program-link {
  padding: 30px;
  display: flex;
  flex-direction: column;
  text-decoration: none;
}
@media (max-width: 500px) {
  .modal-content .programs .program-link {
    width: 100%;
    padding: 0;
    margin: 10px 0;
  }
}
.modal-content .programs .program-link .open-program {
  font-size: 16px;
  font-family: "Roboto-Regular";
  font-weight: 500;
  padding: 20px;
}
@media (max-width: 500px) {
  .modal-content .programs .program-link .open-program {
    padding: 0;
    margin-top: 10px;
    font-size: 14px;
  }
}
