.login-wrapper {
  background-color: #048041;
  padding-top: 90px;
  padding-bottom: 0px;
  background-image: url('../../../images/BackgroundTexture.png');
  background-position: center;
  background-size: contain;
  display: flex;
  flex-direction: row;
}
@media (max-width: 896px) {
  .login-wrapper {
    flex-direction: column;
  }
}
.notice-board {
  width: 80%;
  margin-right: 10%;
  margin-left: auto;
  max-width: 520px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 80vh;
  margin-top: 0px;
  margin-bottom: 0px;
}
@media (max-width: 1200px) and (min-width: 896px) {
  .notice-board {
    margin-left: auto;
    margin-right: auto;
  }
}
@media (max-width: 896px) {
  .notice-board {
    width: auto;
    margin-left: auto;
    margin-right: auto;
  }
}
.notice-board .highlighted-text {
  color: #048041;
}
.notice-board .hanger {
  height: 25px;
  z-index: 2;
}
@media (max-width: 896px) {
  .notice-board .hanger img {
    display: none;
  }
}
.notice-board .notice-outer-container {
  padding: 8px;
  border-radius: 20px;
  background-color: #F5E7D2;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
}
@media (max-width: 500px) {
  .notice-board .notice-outer-container {
    border-radius: 0;
  }
}
.notice-board .notice-container {
  border-radius: 20px;
  padding: 30px;
  background-color: #FFFFFF;
}
@media (max-width: 500px) {
  .notice-board .notice-container {
    border-radius: 0;
  }
}
.notice-board .notice-container .main-heading {
  font-size: 36px;
  color: #048041;
  font-weight: 700;
  font-family: 'Roboto-Medium';
  text-align: center;
  text-transform: uppercase;
}
.notice-board .notice-container .notice-container-box {
  background: #F4F4F4;
  padding: 20px 10px;
  max-width: 370px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.notice-board .notice-container .notice-container-box .noticecontent .heading {
  color: #048041;
  text-decoration: underline;
  font-size: 14px;
  font-family: 'Roboto-Medium';
}
.swing {
  animation: swing ease-out 1s 4 alternate;
  transform-origin: center -20px;
  float: left;
}
.swing:after {
  position: absolute;
  width: 65px;
  height: 48px;
  z-index: 0;
  border-bottom: none;
  border-right: none;
}
/* nail */
.swing:before {
  position: absolute;
  width: 65px;
  height: 48px;
  z-index: 5;
  border-radius: 50% 50%;
}
@keyframes swing {
  0% {
    transform: rotate(3deg);
  }
  100% {
    transform: rotate(-3deg);
  }
}
.login-container {
  width: 80%;
  margin-right: 10%;
  margin-left: auto;
  max-width: 520px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #FFFFFF;
  height: 100%;
  min-height: 82vh;
  margin-top: 0px;
  margin-bottom: 0px;
}
@media (max-width: 1200px) and (min-width: 896px) {
  .login-container {
    margin-left: auto;
    margin-right: auto;
  }
}
@media (max-width: 896px) {
  .login-container {
    margin-left: auto;
    margin-right: auto;
  }
}
@media (max-width: 500px) {
  .login-container {
    width: 100%;
  }
}
.login-container .highlighted-text {
  color: #048041;
}
.login-container .input-parent .error-message {
  padding-left: 0 !important;
}
@media (max-width: 768px) {
  .login-container .input-parent .error-message {
    padding-left: 0 !important;
  }
}
.login-container .input-parent .input-container .input-box {
  min-height: 36px;
  width: 100%;
}
@media (max-width: 896px) {
  .login-container .input-parent .input-container .input-box {
    width: auto;
  }
}
.login-container .input-parent .input-container .input-box .pass-input {
  font-size: 18px;
}
.login-container .container-box {
  padding: 1.5em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media (max-width: 768px) {
  .login-container .container-box {
    max-width: 350px;
    margin: auto;
    padding: 0;
  }
}
@media (max-width: 768px) {
  .login-container .container-box .section-header {
    padding-top: 2.5em;
    padding-bottom: 1em;
  }
}
.login-container .container-box .section-header .section-title {
  text-align: center;
  color: #048041;
  text-transform: uppercase;
  font-size: 36px;
  font-family: 'Roboto-Medium';
  font-weight: normal;
  margin: auto;
}
.login-container .container-box .form-section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 50%;
}
.login-container .container-box .form-section .input-container {
  text-align: right;
  margin-top: 15px;
}
.login-container .container-box .form-section .input-container .input-wrapper {
  width: 100%;
}
@media (max-width: 768px) {
  .login-container .container-box .form-section .input-container {
    text-align: left;
    flex-direction: column;
  }
}
.login-container .container-box .form-section .input-container .input-label {
  font-size: 24px;
  color: #048041;
  width: 120px;
}
@media (max-width: 768px) {
  .login-container .container-box .form-section .input-container .input-label {
    width: unset;
  }
}
.login-container .container-box .form-section .input-container .input-box {
  font-size: 18px;
}
@media (max-width: 768px) {
  .login-container .container-box .form-section .input-container .input-box {
    width: auto;
  }
}
.login-container .container-box .form-section .input-container .password-toggle {
  height: 52px;
}
.login-container .container-box .form-section .login-btn {
  width: 100%;
  margin: 10px auto;
  max-width: 300px;
  background-size: 22px;
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: 75%;
  background-image: url('../../../images/LoginWhite.svg');
}
.login-container .container-box .login-links {
  color: #048041;
  font-size: 16px;
  display: flex;
  justify-content: center;
  margin: 5px 0;
}
.login-container .container-box .login-links .clickable {
  cursor: pointer;
  text-decoration: underline;
}
.login-container .container-box .login-links .clickable:hover {
  opacity: 0.8;
}
.login-container .container-box .login-links a {
  margin-left: 5px;
  font-family: 'Roboto-Medium';
  font-weight: bold;
  cursor: pointer;
}
.login-container .container-box .login-links a:hover {
  opacity: 0.8;
}
