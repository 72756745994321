.file-upload-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1em;
  cursor: pointer;
}
.file-upload-container .error-message {
  width: 100%;
  font-size: 14px;
}
.file-upload-container .drawer-error-message {
  font-size: 12px;
  color: #DB1900;
  font-family: 'Roboto-Medium';
}
.file-upload-container .document-delete {
  cursor: pointer;
}
.file-upload-container .document-delete:hover {
  opacity: 0.7;
}
.file-upload-container .document-view {
  cursor: pointer;
}
.file-upload-container .document-view:hover {
  opacity: 0.7;
}
.file-upload-container .file-upload-loader {
  flex-direction: column;
  height: 100%;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
.file-upload-container .file-upload-loader svg {
  width: 70%;
  max-width: 300px;
  animation: spin 3s linear infinite;
  margin: 20px 0;
}
.file-upload-container .file-upload-loader-drawer {
  flex-direction: row;
  height: 100%;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
.file-upload-container .file-upload-loader-drawer svg {
  width: 40%;
  max-width: 300px;
  animation: spin 3s linear infinite;
  margin: 20px 0;
}
.file-upload-container .document-name {
  overflow: hidden;
  flex: auto;
  color: #048041;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
  width: 100%;
}
.file-upload-container .file-dropzone {
  width: 100%;
  background: #E3DDDA;
  padding: 2em 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  outline: none;
}
.file-upload-container .file-dropzone input {
  outline: #048041;
}
.file-upload-container .file-dropzone p {
  font-family: 'Roboto-Light';
  font-size: 16px;
  color: #001737;
}
.file-upload-container .file-dropzone p b {
  color: #048041;
}
.disabled-file-upload-container {
  opacity: 0.5;
  pointer-events: none;
}
