.filter-component {
  display: flex;
  width: 250px;
  justify-content: center;
  position: relative;
  margin: 0;
}
@media (max-width: 950px) {
  .filter-component {
    left: 0;
    z-index: 1;
    width: 100%;
    display: block;
    flex-direction: column;
    position: relative;
  }
}
.filter-component .filter-label {
  display: flex;
}
.filter-component .filter-label span {
  font-family: 'Roboto-Regular';
}
.filter-component .filter-label .filter-badge {
  margin-left: 0.5em;
  color: #048041;
  background-color: #FFFFFF;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  font-family: 'Roboto-Bold';
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.filter-component .filter-container {
  width: 100%;
  height: 40px;
  opacity: 1;
  margin: 10px 0;
  background-size: 22px;
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: 90%;
  background-image: url('../../../images/FilterVector.svg');
  background-color: #048041;
  color: #FFFFFF;
  font-family: 'Roboto-Light';
  text-align: left;
  padding: 0 1em;
  border-radius: 4px;
  text-transform: uppercase;
}
@media (max-width: 896px) {
  .filter-component .filter-container {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}
.filter-component .filter-container.filter-selected {
  opacity: 1;
}
.filter-component .filter-container .MuiSelect-icon {
  display: none;
}
.filter-component .filter-container.MuiInput-underline:before {
  display: none;
}
.filter-component .filter-container.MuiInput-underline:after {
  display: none;
}
.filter-component .filter-container.MuiInput-underline:hover:not(.Mui-disabled):before {
  display: none;
}
.filter-component .filter-container .MuiSelect-selectMenu {
  padding-right: 35px;
}
.MuiPaper-root .MuiMenu-list .Mui-selected {
  background-color: rgba(4, 128, 65, 0.25) !important;
}
@media (max-width: 910px) {
  .MuiPaper-root .MuiListItem-button .MuiTypography-body1 {
    white-space: pre-wrap;
    font-size: 14px;
  }
}
.filter-option {
  display: flex !important;
  padding: 0 1em !important;
}
.filter-option span {
  padding: 0 2px;
}
.filter-option span .MuiSvgIcon-root {
  height: 15px;
  width: 15px;
}
.filter-option .MuiListItemText-root span {
  font-size: 14px;
}
