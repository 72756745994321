#divChat {
  display: none;
}
.drawer-component {
  display: flex;
  flex-direction: row;
  flex: 100%;
  margin: 10px 0;
}
.drawer-container {
  width: 350px;
  min-width: 350px;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: overlay;
  height: calc(100% - 50px);
  position: relative;
  overflow-x: hidden;
}
@media (max-width: 415px) {
  .drawer-container {
    min-width: 270px;
    width: 270px;
  }
}
.drawer-container.confirm-container {
  height: calc(100% - 100px);
}
.drawer-container .close-icon {
  width: 26px;
  height: 26px;
  position: absolute;
  top: 15px;
  right: 15px;
}
.drawer-container .close-icon:hover {
  opacity: 0.6;
  cursor: pointer;
}
.drawer-container .drawer-heading {
  font-size: 20px;
  display: flex;
  text-align: center;
  font-family: 'Roboto-Medium';
  text-transform: uppercase;
  color: #048041;
  justify-content: center;
  padding: 1em;
  width: auto;
  border-bottom: 1px solid #048041;
}
.drawer-container .drawer-form-container {
  display: flex;
  flex-direction: column;
  height: auto;
  font-size: 16px;
  font-family: 'Roboto-Regular';
  width: 100%;
  padding: 1em 0;
}
.drawer-container .drawer-form-container .drawer-input-container {
  margin: 15px 0 0 0;
}
.drawer-container .drawer-form-container .drawer-input-container .input-container {
  flex-direction: column;
  align-items: flex-start;
}
.drawer-container .drawer-form-container .drawer-input-container .input-container .input-label {
  flex: 100%;
  width: 320px;
  text-align: left;
  margin: 0;
  padding: 0;
  align-items: center;
}
@media (max-width: 415px) {
  .drawer-container .drawer-form-container .drawer-input-container .input-container .input-label {
    width: 300px;
  }
}
.drawer-container .drawer-form-container .drawer-input-container .input-container .input-label .optional-text {
  font-size: 12px;
}
.drawer-container .drawer-form-container .drawer-input-container .input-container .input-label .info-icon img {
  display: inline-block;
  vertical-align: bottom;
  margin: 0 10px;
}
.drawer-container .drawer-form-container .drawer-input-container .input-container .input-box {
  width: 320px;
  margin: 5px 0 0 0;
  height: 30px;
}
@media (max-width: 415px) {
  .drawer-container .drawer-form-container .drawer-input-container .input-container .input-box {
    width: 240px;
  }
}
.drawer-container .drawer-form-container .drawer-input-container .input-container .input-box.enerva-dropdown {
  height: 45px;
  width: 350px;
}
@media (max-width: 415px) {
  .drawer-container .drawer-form-container .drawer-input-container .input-container .input-box.enerva-dropdown {
    width: 270px;
  }
}
.drawer-container .drawer-form-container .drawer-input-container .input-container .input-box.enerva-dropdown.enerva-dropdown__control {
  height: 45px;
}
.drawer-container .drawer-form-container .drawer-input-container .input-container .input-box.enerva-multiselect {
  height: auto;
  width: 350px;
}
@media (max-width: 415px) {
  .drawer-container .drawer-form-container .drawer-input-container .input-container .input-box.enerva-multiselect {
    width: 270px;
  }
}
.drawer-container .drawer-form-container .drawer-input-container .input-container .input-box.enerva-multiselect.enerva-dropdown__control {
  height: 45px;
}
.drawer-container .drawer-form-container .drawer-input-container .input-container .input-box.location-dropdown {
  width: 348px;
  height: 44px;
}
@media (max-width: 415px) {
  .drawer-container .drawer-form-container .drawer-input-container .input-container .input-box.location-dropdown {
    width: 267px;
  }
}
.drawer-container .drawer-form-container .drawer-input-container .input-container .input-box.location-dropdown .location-dropdown__value-container {
  height: 42px;
}
.drawer-container .drawer-form-container .drawer-input-container .input-container .multi-select-checkbox-component {
  width: 100%;
  margin: 5px 0 0 0;
  height: 30px;
}
.drawer-container .drawer-form-container .drawer-input-container .input-container .date-picker {
  width: 200px;
}
.drawer-container .drawer-form-container .drawer-input-container .input-container .file-upload-container {
  width: 310px;
  padding: 0;
  margin: 1em;
}
@media (max-width: 500px) {
  .drawer-container .drawer-form-container .drawer-input-container .input-container .file-upload-container {
    width: 90%;
  }
}
.drawer-container .drawer-form-container .drawer-input-container .input-container .file-upload-container .file-dropzone {
  flex-direction: row;
  padding: 1em;
}
.drawer-container .drawer-form-container .drawer-input-container .input-container .file-upload-container .file-dropzone svg {
  margin: 0 10px 0 0;
}
.drawer-container .drawer-form-container .drawer-input-container .input-container .file-upload-container .file-dropzone p {
  font-size: 12px;
}
.drawer-container .drawer-form-container .drawer-input-container .error-message {
  padding: 5px 0;
}
.drawer-container .enerva-btn {
  background-color: #048041;
}
.drawer-container .enerva-btn.inversed-btn {
  background: #FFFFFF;
  border: 1px solid #048041;
  color: #048041;
  text-align: center;
  float: right;
}
@media (max-width: 1024px) {
  .drawer-container .enerva-btn.inversed-btn {
    max-width: unset;
  }
}
.drawer-container .drawer-submit-btn {
  position: fixed;
  bottom: 0;
  width: 380px;
  margin: 0;
  background: #048041;
  padding: 15px 0;
  border: none;
  border-radius: 0;
}
.drawer-container .drawer-submit-btn.inversed-btn {
  bottom: 49px;
  background: #FFF;
  border: 1px solid #048041;
  color: #048041;
}
@media (max-width: 415px) {
  .drawer-container .drawer-submit-btn {
    width: 300px;
  }
}
