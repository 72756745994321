.enerva-btn {
  font-family: 'Roboto-Medium';
  background: #048041;
  border: 1px solid #CCC;
  margin: 10px;
  color: #FFF;
  border-radius: 4px;
  padding: 10px 25px;
  font-size: 16px;
  font-weight: normal;
}
.enerva-btn:hover {
  opacity: 0.8;
}
@media (max-width: 500px) {
  .enerva-btn {
    font-size: 12px;
  }
}
.large-btn {
  padding: 10px 20px;
  font-size: 24px;
}
.uppercase-btn {
  font-family: 'Roboto-Medium';
  text-transform: uppercase;
}
.inversed-btn {
  background: #FFFFFF;
  border: 1px solid #048041;
  color: #048041;
}
@media (max-width: 500px) {
  .inversed-btn {
    font-size: 12px;
  }
}
.disabled-btn {
  background: #707070 !important;
  cursor: not-allowed !important;
  pointer-events: none;
}
.inversed-btn.disabled-btn {
  opacity: 0.4;
  background: #FFFFFF;
}
