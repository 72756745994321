.post-project-review-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  min-height: 120px;
}
@media (max-width: 330px) {
  .post-project-review-container {
    min-height: 40px;
  }
}
.post-project-review-container h3 {
  color: #535765;
  font-family: 'Roboto-Medium';
  font-size: 20px;
  text-transform: unset;
}
@media (max-width: 896px) {
  .post-project-review-container h3 {
    font-size: 16px;
  }
}
@media (max-width: 330px) {
  .post-project-review-container h3 {
    font-size: 12px;
  }
}
.post-project-review-container .form-inner-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}
@media (max-width: 896px) {
  .post-project-review-container .form-inner-section {
    flex-direction: column;
  }
}
.post-project-review-container .form-inner-section .form-label {
  justify-content: center;
  align-items: center;
  font-size: 18px;
}
@media (max-width: 896px) {
  .post-project-review-container .form-inner-section .form-label {
    font-size: 14px;
  }
}
.post-project-review-container .form-inner-section .form-element {
  justify-content: center;
  align-items: center;
  padding: 0px;
  margin-top: 0px;
  width: 40%;
}
@media (max-width: 896px) {
  .post-project-review-container .form-inner-section .form-element {
    width: 80%;
    margin: 10px 0;
  }
}
.post-project-review-container .form-inner-section .form-element .input-container {
  margin-top: 0px;
}
.post-project-review-container .form-inner-section p {
  font-size: 16px;
  text-align: left;
  width: 85%;
}
.post-project-review-container .form-inner-section p span {
  font-family: 'Roboto-Medium';
  color: #535765;
}
@media (max-width: 330px) {
  .post-project-review-container .form-inner-section p {
    font-size: 8px;
  }
}
.post-project-review-container .form-text-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.post-project-review-container .form-text-section a {
  font-family: 'Roboto-Bold';
  color: #048041;
  text-decoration: none;
}
.post-project-review-container .form-text-section a:hover {
  opacity: 0.7;
}
.post-project-review-container .form-text-section .check-box-line {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 90%;
}
.post-project-review-container .form-text-section .check-box-line .check-box {
  padding-right: 1em;
}
.post-project-review-container .form-text-section p {
  font-size: 16px;
  text-align: left;
  width: 90%;
}
@media (max-width: 896px) {
  .post-project-review-container .form-text-section p {
    font-size: 14px;
  }
}
.post-project-review-container .form-text-section p span {
  font-family: 'Roboto-Medium';
  color: #535765;
}
.post-project-review-container .form-text-section p .green-label {
  color: #048041;
}
.post-project-review-container .form-text-section .centered-text {
  text-align: center;
  font-size: 20px;
}
@media (max-width: 896px) {
  .post-project-review-container .form-text-section .centered-text {
    font-size: 14px;
  }
}
