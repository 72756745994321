.signup-container {
  width: 90%;
  max-width: 1024px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.signup-container .input-parent .input-container .enerva-dropdown {
  width: 64.5%;
}
@media (max-width: 896px) {
  .signup-container .input-parent .input-container .enerva-dropdown {
    width: 100%;
  }
}
.signup-container .cancel-button {
  appearance: none;
  background: transparent;
  border: none;
  display: block;
  position: absolute;
  top: 3%;
  left: 2%;
  font-size: 16px;
  font-weight: normal;
  font-family: 'Roboto-Regular';
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 896px) {
  .signup-container .cancel-button {
    position: absolute;
    top: 0.8%;
    left: 0;
    padding-top: 1.2em;
  }
}
.signup-container .cancel-button img {
  padding-right: 5px;
  width: 6px;
}
.signup-container .section-header {
  padding-top: 2.5em;
  padding-bottom: 2.5em;
}
@media (max-width: 896px) {
  .signup-container .section-header {
    padding-top: 5em;
    padding-bottom: 1em;
  }
}
.signup-container .section-header h2 {
  max-width: 552px !important;
}
.signup-container .section-header .section-title {
  text-align: center;
  color: #048041;
  text-transform: uppercase;
  font-size: 24px;
  font-family: 'Roboto-Medium';
  font-weight: normal;
  max-width: 500px;
  margin: auto;
}
@media (max-width: 896px) {
  .signup-container {
    width: 90%;
    margin: auto;
    text-align: center;
  }
}
.signup-container .checkboxes-section {
  padding-top: 2em;
  text-align: left;
  width: auto;
}
.signup-container .form-bottom {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.signup-container .form-bottom .signup-btn {
  width: 100%;
  margin-top: 15px;
  max-width: 250px;
  text-align: center;
  margin-right: 30px;
}
@media (max-width: 896px) {
  .signup-container .form-bottom .signup-btn {
    max-width: unset;
    margin-right: 0px;
    margin-left: 0px;
  }
}
.signup-container .container-box {
  width: 90%;
  box-shadow: 0px 0px 0px #5A71D01B;
  border: 0.5px solid #98989A;
  padding: 1.5em;
  text-align: center;
  min-height: 400px;
}
.signup-container .container-box .vertical-separator-box {
  display: flex;
  opacity: 0.5;
  flex: 8%;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.signup-container .container-box .vertical-separator-box .separator {
  background-color: #048041;
  width: 1px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 32%;
  height: 90%;
}
@media (max-width: 896px) {
  .signup-container .container-box .vertical-separator-box {
    display: none;
  }
}
.signup-container .container-box .form-sections {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
@media (max-width: 896px) {
  .signup-container .container-box .form-sections {
    flex-direction: column;
  }
}
.signup-container .container-box .form-sections .form-section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 44%;
}
@media (max-width: 896px) {
  .signup-container .container-box .form-sections .form-section {
    flex: unset;
  }
}
@media (max-width: 896px) {
  .signup-container .container-box .form-sections .form-section .info-icon {
    display: none;
  }
}
@media (min-width: 896px) {
  .signup-container .container-box .form-sections .form-section .q-dropdown {
    display: grid;
    grid-template-columns: 90% 10%;
  }
  .signup-container .container-box .form-sections .form-section .q-dropdown .input-parent .input-container {
    align-items: center;
  }
  .signup-container .container-box .form-sections .form-section .q-dropdown .input-parent .input-container .input-label {
    width: 55%;
  }
  .signup-container .container-box .form-sections .form-section .q-dropdown .input-parent .input-container .input-box {
    width: 64.5%;
    height: 40px;
  }
  .signup-container .container-box .form-sections .form-section .q-dropdown .info-icon {
    padding-top: 50px;
  }
}
.signup-container .container-box .form-sections .form-section .input-container {
  margin-top: 15px;
}
@media (max-width: 896px) {
  .signup-container .container-box .form-sections .form-section .input-container {
    margin-top: 5px;
  }
}
